import React from "react";
import {
  FaCalendarAlt,
  FaInfoCircle,
  FaLock,
  FaCookieBite,
  FaUserSecret,
  FaShieldAlt,
  FaSyncAlt,
  FaEnvelope,
  FaPhone,
  FaInstagram,
} from "react-icons/fa";

const Privacy = () => {
  return (
    <div className="bg-gray-100 py-16 px-6 sm:px-12 md:px-20 lg:px-32">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8 md:p-12">
        <h1 className="text-2xl md:text-3xl font-bold font-bree text-blue-600 text-center mb-8">
          Privacy Policy for Gebeta
        </h1>

        <p className="text-gray-600 mb-8 text-sm flex items-center">
          <FaCalendarAlt className="text-blue-500 mr-2" />
          <strong>Effective Date:</strong> November 5, 2024
        </p>

        <section className="mb-6">
          <p className="text-gray-700">
            This Privacy Policy outlines how Gebeta, a digital menu system
            providing Software as a Service (SaaS) for restaurants, hotels, and
            cafes, protects your information. By using our services, you agree
            to the collection and use of information in accordance with this
            policy.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaInfoCircle className="text-blue-500 mr-2" /> Introduction
          </h2>
          <p className="text-gray-700">
            Welcome to Gebeta! We respect your privacy and are committed to
            protecting your data...
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaLock className="text-blue-500 mr-2" /> Information We Collect
          </h2>
          <p className="text-gray-700">
            We do not collect any personal information from users of our digital
            menu system...
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaCookieBite className="text-blue-500 mr-2" /> Cookies and Tracking
          </h2>
          <p className="text-gray-700">
            Our platform does not use cookies or similar tracking technologies
            to gather personal data...
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaUserSecret className="text-blue-500 mr-2" /> Disclosure of Your
            Information
          </h2>
          <p className="text-gray-700">
            We do not share, sell, or disclose any user information to third
            parties...
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaShieldAlt className="text-blue-500 mr-2" /> Security
          </h2>
          <p className="text-gray-700">
            While we do not collect or store personal information, we take
            security seriously...
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaUserSecret className="text-blue-500 mr-2" /> User Rights
          </h2>
          <p className="text-gray-700">
            Even though we do not collect personal information, we respect your
            rights regarding your data...
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaSyncAlt className="text-blue-500 mr-2" /> Changes to This Privacy
            Policy
          </h2>
          <p className="text-gray-700">
            We may update this Privacy Policy from time to time. If we make
            changes...
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaEnvelope className="text-blue-500 mr-2" /> Contact Us
          </h2>
          <p className="text-gray-700">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <div className="mt-4 pl-4">
            <p className="text-gray-700">
              <strong>Gebeta Digital Menu Service</strong>
            </p>
            <p className="text-gray-700 flex items-center">
              <FaEnvelope className="text-blue-500 mr-2" /> Email:
              mail@gebeta.com
            </p>
            <p className="text-gray-700 flex items-center">
              <FaPhone className="text-blue-500 mr-2" /> Phone: (+251) 900296860
            </p>
            <p className="text-gray-700 flex items-center">
              <FaInstagram className="text-blue-500 mr-2" /> Social Media:
              @gebeta_official
            </p>
          </div>
        </section>

        <p className="text-gray-600 text-sm text-center">
          By using the Gebeta Service, you agree to the terms of this Privacy
          Policy...
        </p>
      </div>
    </div>
  );
};

export default Privacy;
