import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import {
  FaTelegram,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

const Header = () => {
  return (
    <header className="py-2 shadow-lg bg-gradient-to-r from-indigo-700 via-purple-400 to-pink-600">
      <div className="max-w-7xl mx-auto px-4 shadow-2x1 sm:px-6 lg:px-8 flex justify-center">
        <img
          src="/assets/logo.png"
          alt="Logo"
          className="w-24 h-auto mr-4 shadow-2x1 object-contain"
        />
      </div>
    </header>
  );
};

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  return (
    <div className="relative flex justify-center w-full">
      <input
        id="search"
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="SEARCH"
        className="w-full md:w-3/4 lg:w-1/2 font-bree bg-gradient-to-r from-cyan-600 to-purple-600 text-white placeholder-white py-3 pl-10 pr-4 rounded-none shadow-2xl outline-none transition-all duration-300 hover:rounded-2xl focus:rounded-2xl hover:shadow-2xl hover:scale-95 focus:ring-0 focus:outline-none"
        style={{
          width: "calc(100%)",
          height: "3rem",
        }}
        tabIndex="0"
      />
      <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white text-lg" />
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 md:py-8">
      <div className="container mx-auto flex flex-col items-center px-4">
        <div className="flex flex-col items-center mb-6">
          <a
            href="https://www.gebetas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/Gebeta.png" alt="Gebeta Logo" className="w-24 mb-2" />
          </a>
          <p className="text-xl md:text-2xl font-dancing">
            "Lettuce Take a Byte!"
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            Contact Us
          </h3>
          <p className="font-salsa text-center">
            Email:{" "}
            <a href="mailto:mail@gebeta.com" className="text-green-400">
              mail@gebetas.com
            </a>
          </p>
          <p className="font-salsa text-center">
            Phone:{" "}
            <a href="tel:+1234567890" className="text-green-400">
              (+251) 900296860
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            Follow Us
          </h3>
          <div className="flex space-x-4 justify-center">
            <a
              href="https://t.me/Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://facebook.com/Gebeta Menu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://twitter.com/Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://instagram.com/Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://youtube.com/@Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://linkedin.com/in/BenjaminMenedo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-2xl hover:text-green-400" />
            </a>
          </div>
        </div>{" "}
        <div className="flex flex-col items-center mb-3 md:mb-0">
          <p className="font-salsa text-center">
            <a
              href="/PrivacyPolicy" // Ensure this path matches your routing
              className="text-green-400 hover:underline"
            >
              PRIVACY POLICY
            </a>
          </p>
        </div>{" "}
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <p className="font-salsa text-center">
            <a
              href="/Terms" // Ensure this path matches your routing
              className="text-green-400 hover:underline"
            >
              TERMS OF SERVICE
            </a>
          </p>
        </div>
      </div>
      <div className="text-center mt-6 md:mt-8 font-salsa">
        <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
          © 2024 Gebeta. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
// Skeleton Loader Component
const SkeletonLoader = () => {
  return (
    <div className="bg-gray-300 animate-pulse rounded-lg p-4 flex items-center mb-4">
      <div className="w-20 h-20 bg-gray-400 rounded-lg mr-4"></div>
      <div className="flex-1">
        <div className="h-5 bg-gray-400 rounded mb-2"></div>
        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
      </div>
    </div>
  );
};

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching
    fetch("https://gebetas.com/api.php")
      .then((response) => response.json())
      .then((data) => {
        setMenuItems(data);
        setLoading(false); // Set loading to false after fetching
      })
      .catch((error) => {
        console.error("Error fetching menu items:", error);
        setLoading(false); // Set loading to false on error
      });
  }, []);

  const filteredItems = menuItems.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 py-4">
            {Array.from({ length: 25 }).map((_, index) => (
              <SkeletonLoader key={index} />
            ))}
          </div>
        </div>
      ) : (
        <div style={{ userSelect: "none" }}>
          <Header />
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div className="bg-gradient-to-r from-indigo-500 via-purple-600 to-pink-500 py-12 text-white font-serif font-bold">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl text-center">MENU</h2>
              <div className="grid grid-cols-1 gap-8">
                {filteredItems.length ? (
                  filteredItems.map((item) => (
                    <React.Fragment key={item.id}>
                      {item.customText && (
                        <div className="inline-block p-2 border-2 border-white rounded-full mt-4 mb-4">
                          <h3 className="text-2xl text-center text-white font-bold tracking-widest">
                            {item.customText}
                          </h3>
                        </div>
                      )}
                      <div
                        className={`flex items-center border border-transparent rounded-xl p-4 shadow-lg bg-black bg-opacity-40 hover:bg-opacity-60 transition-all duration-300 ease-in-out hover:border-fuchsia-400 hover:shadow-fuchsia-400 group ${
                          item.stock ? "" : "line-through text-red-500"
                        }`}
                      >
                        <img
                          src={`/assets/${item.image}`} // Dynamically load images
                          alt={item.name}
                          className="w-20 h-20 rounded-lg object-cover mr-4 shadow-lg group-hover:shadow-fuchsia-400 transition-shadow duration-300 ease-in-out"
                        />
                        <div className="flex-1 pl-2">
                          <h3 className="text-lg font-bold text-white group-hover:text-fuchsia-400 tracking-widest break-words">
                            {item.name}
                          </h3>
                        </div>
                        <div className="flex flex-col justify-between items-center p-2 rounded-lg bg-black bg-opacity-40 group-hover:shadow-lg transition-shadow duration-300 ease-in-out">
                          <span className="text-lg font-bold text-white group-hover:text-fuchsia-400">
                            {item.price}
                          </span>
                          <span className="text-sm text-gray-100 group-hover:text-fuchsia-400">
                            {item.currency}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <p className="text-center text-white font-bree">
                    No items available
                  </p>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Menu;
